<template>
	<project-holder>
		<template v-slot:headerContent>
			<content-holder>
				<template v-slot:left>
					<h1 class="bold">EEN Project</h1>
					<h4>
						This is a project I participated in as a Lead frontend developer at
						Trineo.
					</h4>
					<h4 class="bold">
						Main challenges:
					</h4>
					<ul>
						<li>
							navigating a complex legacy web application with over 100,000
							lines of code.
						</li>
						<li>
							preparing a new application using good practices and appropriate
							architecture
						</li>
						<li>
							cooperation in a company that has 150+ programmers
						</li>
						<li>
							team management
						</li>
						<li>
							preparing simple solutions based on complex customer requirements
						</li>
						<li>
							effective cooperation in a cross functional team
						</li>
						<li>
							managing stress, deadlines, priorities and critical incidents
						</li>
					</ul>
				</template>
				<template v-slot:right>
					<img src="@/assets/projects/dev/een/big-een.png" alt="" />
				</template>
			</content-holder>
		</template>
		<template v-slot:mainContent>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						My response: Adaptation to the environment.
					</h4>
					<p>
						This project demanded a significant shift in my approach. While
						navigating the complexities of a large-scale legacy system and the
						inherent stresses of a fast-paced environment, I recognized the need
						to adapt. However, I remained committed to upholding the best
						practices and principles I had cultivated throughout my career.
						These core principles guided my approach
					</p>

					<p>The principles I wanted to stick to were:</p>
					<ul>
						<li>
							establishing clear routines
						</li>
						<li>
							defining robust processes
						</li>
						<li>
							fostering transparent communication
						</li>
						<li>
							precise planning
						</li>
						<li>
							learning learning learning
						</li>
					</ul>
					<p>
						I will briefly discuss each aspect.
					</p>
				</template>
				<template v-slot:right></template>
			</content-holder>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						Daily routines
					</h4>
					<p>
						The dynamic nature of this project, with constantly shifting
						requirements and priorities, demanded a high degree of composure and
						adaptability. To effectively navigate this environment, I
						established a consistent daily routine.
					</p>
					<p>
						Each morning, I began by reviewing all relevant communication
						channels, including emails, Jira tickets, and Zulip messages. This
						comprehensive review allowed me to gain a clear understanding of the
						day's priorities and any urgent issues that required immediate
						attention.
					</p>
					<p>
						When critical incidents arose, I prioritized calm and decisive
						action. By delegating tasks strategically and effectively managing
						stress within the team, I ensured a focused and efficient response.
						This approach not only minimized panic and confusion but also
						significantly improved our ability to resolve issues quickly. In
						fact, by streamlining the response process, we were able to reduce
						the number of team members involved in resolving critical incidents
						by at least 50%, allowing the team to maintain focus on core
						deliverables.
					</p>
					<p>
						Once the immediate priorities were addressed and a clear plan was
						established, I would proceed with code reviews. Daily code reviews
						were crucial for maintaining a high level of code quality and
						ensuring the timely implementation of new features.
					</p>
					<p>Then I could start writing the code.</p>
				</template>
			</content-holder>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						Biweekly routines
					</h4>
					<p>
						Successful sprints hinge on meticulous planning. Every two weeks, I
						analyzed requirements, proactively identified potential roadblocks,
						and engaged other teams early in the planning process. This
						proactive approach improved team predictability by 25%, reducing a
						number of "unexpected issues" significantly.
					</p>
				</template>
			</content-holder>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						Specific processes
					</h4>
					<p>
						In a large organization, well-defined processes are crucial for
						maintaining order and efficiency. To prevent chaos, I established
						clear guidelines for task submission and bug reporting. This
						included defining mandatory elements for all tasks, ensuring that
						all necessary information was readily available before handing off
						work to other teams.
					</p>
				</template>
			</content-holder>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						Transparent communication
					</h4>
					<p>
						In a fast-paced environment with multiple concurrent projects,
						maintaining priorities and alignment is crucial. To prevent
						confusion and ensure that the most critical tasks were addressed
						first, I implemented a system of public and transparent
						communication. All requests for assistance from other teams were
						required to be publicly shared. This centralized communication
						channel provided a clear overview of all outstanding requests,
						enabling efficient prioritization and ensuring that no critical
						requests were overlooked.
					</p>
					<p>
						Furthermore, to maintain consistency and prevent errors, I
						established Jira tickets and PRD/TRD documents as the single source
						of truth for all project requirements. This standardized approach
						minimized the risk of duplication, misinterpretation, and
						inconsistencies, ensuring that all team members were working from
						the same understanding.
					</p>
				</template>
			</content-holder>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						Learning learning learning!
					</h4>
					<p>
						The legacy codebase presented a significant technical challenge.
						Implementing even minor changes proved to be a complex and
						time-consuming process due to the application's age and the
						resulting architectural limitations. Recognizing this, I made a
						conscious effort to improve the codebase incrementally. Inspired by
						the "Boy Scout Rule," I aimed to leave the code slightly better than
						I found it with each interaction. To achieve this, I actively sought
						to enhance my knowledge of software design principles, focusing on
						concepts such as:
					</p>
					<ul>
						<li>Object oriented programming (OOP)</li>
						<li>SOLID</li>
						<li>Clean Code</li>
						<li>Domain-Driven Design (DDD)</li>
						<li>Test-Driven Development (TDD)</li>
						<li>Gang of Four (GoF) Design Patterns</li>
					</ul>
				</template>
			</content-holder>
		</template>
		<template v-slot:footerContent>
			<content-holder>
				<template v-slot:left>
					<h4 class="bold">
						OVERVIEW
					</h4>
					<p>
						Reflecting on my time working on the Eagle Eye Networks project, I
						am incredibly grateful for the opportunity to contribute to such a
						complex and challenging endeavor. The experience has not only
						allowed me to hone my technical skills but has also fostered my
						ability to lead, collaborate, and problem-solve in dynamic
						environments. I am particularly proud of the positive impact I was
						able to make on the project, such as improving code quality,
						streamlining processes, and enhancing team collaboration. This
						project has been instrumental in shaping me into the developer I am
						today.
					</p>
					<p>
						Thank you for reading
					</p>
					<p>
						If you are interested in hiring me I invite you to check my About me
						page where you can check my actual full list of skills, and send me
						and email that can be find the the contact page.
					</p>
					<p>
						<router-link to="/about">About page</router-link> <br />
						<router-link to="/contact">Contact page</router-link>
						<br />
					</p>
				</template>
			</content-holder>
		</template>
	</project-holder>
</template>

<script>
import ProjectHolder from "@/components/storytelling/ProjectHolder.vue";
import ContentHolder from "@/components/storytelling/ContentHolder.vue";

export default {
	components: {
		ProjectHolder,
		ContentHolder
	}
};
</script>

<style lang="scss" scoped></style>
